
import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import "../../scss/popup.scss";
import { isValidEmail } from '../../utils/utils';
import { submitInventivEwebinarForm } from '../../utils/campaign';
import CustomPopup from './custom-popup';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const ContactPopup = forwardRef((props, ref) => {
    console.log('props ', props)
    const [show, setShow] = React.useState(false);
    const [showForm, setShowForm] = useState(true);
    const [name, setName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const popupCloseHandler = (e) => {
        setShow(false);
    };
    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        close() {
            setShow(false);
        },

        open(email = '') {
            if (email) {
                setEmail(email);
            }
            setShow(true);
        }
    }));

    const submit = async (e) => {
        e.preventDefault();
            // check 
        if (firstName && lastName && email && phone) {
            // validate email
            if (!isValidEmail(email)) {
                alert('Email is invalid!!!');
                return;
            }
            
            // active campaign
            const ewebinarFrmData = {
                firstname: `${firstName}`,
                lastname:  `${lastName}`,                
                email: email,
                phone: phone || '',
            }

            submitInventivEwebinarForm(ewebinarFrmData);

            //
            const data = {
                email: email,
                name: `${firstName} ${lastName}`,
                phone: phone || '',
                subject: `[Inventiv.org] ${email} request a Demo`,
                message: '',
                site: 'inventiv.org'
            }
            // send mail
            fetch("https://api.trustyip.com/subcribers/add", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json"
                },
                "body": JSON.stringify(data)
            })
            .then(response => response.json())
            .then(response => {
                // reset
                setShowForm(false);
                setEmail('');
                setFirstName('');
                setLastName('');
                setPhone('');
                // action
                if (props.page) {
                    document.location.href = props.page
                }
            })
            .catch(err => {
                alert('There is something wrong. Please try again later!');
                console.log(err);
            });
            // close popup
            // childRef.current?.close();
        } else {
            alert('Please enter your information!');
        }
    }
    return (
        <>
            <CustomPopup
                onClose={popupCloseHandler}
                show = {show}
                title="">
                    {
                    showForm ? 
                    <div>
                        <h3 className="text-center fw-bold mb-4">Please provide your contact information</h3>
                        <Form >
                            <Form.Group className="mb-1" controlId="formBasicName">
                                <Row>
                                    <Col>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" placeholder="First name"  required="true"  value={firstName} onChange={e => setFirstName(e.target.value)} />
                                    </Col>
                                    <Col>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" placeholder="Last name"  required="true"  value={lastName} onChange={e => setLastName(e.target.value)} />
                                    </Col>
                                </Row>
                                
                            </Form.Group>

                        <Form.Group className="mb-1" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Your email" required="true"  value={email} onChange={e => setEmail(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="text" placeholder="Your phone"  value={phone} onChange={e => setPhone(e.target.value)} />
                        </Form.Group>

                        <button className='btn btn-warning w-100' onClick={submit}>Submit</button>
                        </Form>
                    </div> 
                    :
                    <div class="_form-thank-you">
                        <h3 className='text-center'>Thank you!</h3>
                        <p className='text-center'>
                        Thank you for contacting us. We will follow up with you soon 
                        </p>
                        <p className='text-center'>Inventiv.org.</p>
                    </div>
                    }
            </CustomPopup>
        </>
    )
});

ContactPopup.propTypes = {
    page: PropTypes.string,
}

export default ContactPopup;